import React from "react";
import { graphql } from "gatsby";
// import {Link} from "gatsby";

import Head from "../components/head";
import Layout from "../components/layout";
import Section from "../components/section";
import Grid from "../components/grid";
import Card from "../components/card";

const PageTitle = "Services et Prestations";

export default ({ data }) => (
  <div>
    <Head PageTitle={PageTitle} PageSlug="/services/" />

    <Layout>
      <Section Style="secondary uk-light uk-text-center">
        <h3>{PageTitle}</h3>
        <div>
          Je vous propose mes services pour mettre en place une solution
          numérique adaptée à vos besoins...
        </div>
      </Section>

      <Section Style="muted">
        <Grid Col="3">
          {data.allMarkdownRemark.edges.map(({ node }) => {
            return (
              <div key={node.id}>
                <Card
                  ImageFluid={
                    node.frontmatter.cover_image.childImageSharp.fluid
                  }
                  Label=""
                  Title={node.frontmatter.title}
                  Subtitle=""
                  Intro={node.excerpt}
                  LinkUrl={node.fields.slug}
                  LinkText="Découvrir"
                />
              </div>
            );
          })}
        </Grid>
      </Section>
    </Layout>
  </div>
);

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: ASC }
      filter: { frontmatter: { categoryId: { eq: "services" } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY")
            cover_image {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 225) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            skills
          }
          fields {
            slug
          }
          excerpt(pruneLength: 90)
        }
      }
    }
  }
`;
